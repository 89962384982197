import App from "../App";
import { Draggable } from "../vendor/Draggable";
import { InertiaPlugin } from "../vendor/InertiaPlugin";
import { gsap } from "gsap";
gsap.registerPlugin(Draggable);
gsap.registerPlugin(InertiaPlugin);

const Drag = {
  init: (_) => {
    Drag.create();
  },
  create: (_) => {
    if (Drag.draggable || App.isMobile) return;
    Drag.draggable = Draggable.create(".drag, .drag-inside", {
      type: "x,y",
      bounds: document.getElementById("viewport"),
      inertia: true,
      throwResistance: 2000,
      dragResistance: 0.1,
      cursor: "grab",
      activeCursor: "grabbing",
      force3D: true,
      zIndexBoost: true,
      onDrag: Drag.colors,
      onDragEnd: Drag.stopColors,
      // onThrowUpdate: Drag.resize,
      liveSnap: {
        x: function (value) {
          //snap to the closest increment of 50.
          return Math.round(value / (App.width / 46)) * (App.width / 46);
        },
        y: function (value) {
          //snap to the closest increment of 25.
          return Math.round(value / (App.height / 27)) * (App.height / 27);
        },
      },
    });
  },
  colors: (el) => {
    document.getElementById("background-background").classList.add("animate");
    Drag.resize(el);
  },
  stopColors: (_) => {
    document.getElementById("background-background").classList.remove("animate");
  },
  resize: (el) => {
    if (el.target.classList.contains("resize") && el.target._gsTransform) {
      // console.log(el.target._gsTransform)
      // el.target.style.width = App.width - (el.target._gsTransform.x*2) + 'px';
    }
  },
  reset: (_) => {
    if (Drag.draggable && Draggable.get(".drag, .drag-inside")) {
      Draggable.get(".drag, .drag-inside").kill();
      gsap.set(".drag, .drag-inside", {
        clearProps: "all",
      });
      Drag.create();
    } else {
      Drag.create();
    }
  },
};
export default Drag;
