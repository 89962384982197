import App from "../App";
import { Draggable } from "../vendor/Draggable";
import { InertiaPlugin } from "../vendor/InertiaPlugin";
import { gsap } from "gsap";
gsap.registerPlugin(Draggable);
gsap.registerPlugin(InertiaPlugin);

const Drawer = {
  draggables: [],
  init: (_) => {
    Drawer.create();
  },
  create: (_) => {
    if (Drawer.draggables.length || App.isMobile) return;

    Drawer.items = document.querySelectorAll(".drawer");

    Drawer.items.forEach((drawer, i) => {
      const { direction } = drawer.dataset;
      const axe = direction === "top" || direction === "bottom" ? "y" : "x";
      drawer.grab = drawer.querySelector(".grab");

      gsap.fromTo(
        drawer,
        1,
        {
          y: axe === "y" ? (direction === "top" ? 100 : -100) + "%" : null,
          x: axe === "x" ? (direction === "left" ? 100 : -100) + "%" : null,
        },
        {
          y:
            axe === "y"
              ? (direction === "top" ? 1 : -1) *
                (drawer.offsetHeight - drawer.grab.offsetHeight)
              : null,
          x:
            axe === "x"
              ? (direction === "left" ? 1 : -1) *
                (drawer.offsetWidth - drawer.grab.offsetWidth)
              : null,
          force3D: true,
          ease: "expo.out",
          onComplete: (_) => {
            Draggable.create(drawer, {
              type: axe,
              edgeResistance: 1,
              dragResistance: 0,
              bounds:
                axe === "x"
                  ? {
                      minX:
                        direction === "left"
                          ? 1 * (drawer.offsetWidth - drawer.grab.offsetWidth)
                          : -1 * (drawer.offsetWidth - drawer.grab.offsetWidth),
                      maxX: 0,
                    }
                  : {
                      minY: 0,
                      maxY:
                        (direction === "top" ? 1 : -1) *
                        (drawer.offsetHeight - drawer.grab.offsetHeight),
                    },
              inertia: true,
              force3D: true,
              zIndexBoost: true,
              onDrag: Drawer.colors,
              onDragEnd: Drawer.stopColors,
              liveSnap: {
                x: function (value) {
                  //snap to the closest increment of 50.
                  return Math.round(value / (App.width / 60)) * (App.width / 60);
                },
                y: function (value) {
                  //snap to the closest increment of 25.
                  return Math.round(value / (App.height / 46)) * (App.height / 46);
                },
              },
            });
          },
        }
      );

      drawer.grab.addEventListener("click", () => {
        gsap.to(drawer, {
          duration: 0.8,
          x: 0,
          y: 0,
          ease: "expo.out",
        });
      });
    });
  },
  colors: (el) => {
    document.getElementById("background-background").classList.add("animate");
  },
  stopColors: (_) => {
    document
      .getElementById("background-background")
      .classList.remove("animate");
  },
  reset: (_) => {
    if (Drawer.draggables.length) {
      Draggable.get(".drawer").kill();
      gsap.set(".drawer", {
        clearProps: "all",
      });
      Drawer.create();
    } else {
      Drawer.create();
    }
  },
};
export default Drawer;
