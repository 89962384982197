import lazysizes from "lazysizes";
import optimumx from "lazysizes";
import "lazysizes/plugins/object-fit/ls.object-fit.js";
import "lazysizes/plugins/unveilhooks/ls.unveilhooks.js";

const lazySetup = {
  init: () => {},
};

export default lazySetup;
