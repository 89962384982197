import Drag from "./Drag";
import Drawer from "./Drawer";
import Slider from "./Slider";
import imagesLoaded from "imagesloaded";
const Loader = {
  isLoaded: false,
  node: null,
  init: (_) => {
    Loader.node = document.querySelector("#loader");
  },
  loading: (_) => {},
  loaded: (_) => {
    // if (Loader.node) Loader.node.style.display = 'none';
    imagesLoaded(document.querySelector("#viewport"), function (instance) {
      Drag.init();
      Drawer.init();
      Slider.init();
      if (Loader.node) Loader.node.remove();
    });
  },
};
export default Loader;
