import LazySizes from './components/Lazysizes';
import Loader from './components/Loader';
import Links from './components/Links';
import Slider from './components/Slider';
import Drag from './components/Drag';
import Drawer from './components/Drawer';
// import MouseCursor from './components/MouseCursor';
import debounce from 'lodash/debounce';

const App = {
  root: window.location.hostname == 'localhost' ? '/xxx/www' : '',
  init: _ => {
    Loader.init();
    App.sizeSet();
    App.interact();
    window.addEventListener('resize', debounce(App.sizeSet, 300), false)
    Loader.loaded();
  },
  sizeSet: _ => {
    App.width = (window.innerWidth || document.documentElement.clientWidth)
    App.height = (window.innerHeight || document.documentElement.clientHeight)
    App.isMobile = App.width <= 767
    Drag.reset()
    Drawer.reset()
  },
  addListener: (target, eventType, func = () => {}) => {
    const targets = document.querySelectorAll('[event-target="' + target + '"]');
    [...targets].forEach(elem => {
      elem.addEventListener(eventType, func)
    })
  },
  interact: _ => {
    Links.init();
    // MouseCursor.init();
    App.addListener('link', 'click', e => {
      window.open(e.currentTarget.dataset.link, '_blank')
    })
  }
};

export default App;
