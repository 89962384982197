import App from "../App";
import { Draggable } from "../vendor/Draggable";
import { InertiaPlugin } from "../vendor/InertiaPlugin";
import { gsap } from "gsap";
gsap.registerPlugin(Draggable);
gsap.registerPlugin(InertiaPlugin);

const Slider = {
  draggables: [],
  init: (_) => {
    Slider.item = document.querySelector("#images-slider .inner");
    Slider.wrap = gsap.utils.wrap(0, 1);
    Slider.create();
  },
  create: (_) => {
    Slider.resize();
    Draggable.create(Slider.item, {
      type: "x",
      trigger: Slider.item,
      //   dragResistance: 0.75,
      //   inertia: {
      //     // maxDuration: 3,
      //     throwResistance: 5000,
      //     // overshootTolerance: 0,
      //   },
      force3D: true,
      onPressInit: function () {
        //   Slider.animation.pause();
        Slider.startPos = Slider.wrap(this.x);
      },
      onDrag: function () {
        let prog = Slider.wrap(-this.x / Slider.contentWidth);
        Slider.animation.progress(prog);
      },
      onThrowUpdate: function () {
        let prog = Slider.wrap(-this.x / Slider.contentWidth);
        Slider.animation.progress(prog);
      },
      // onThrowComplete: function() {
      //   Slider.animation.play();
      //   gsap.fromTo(Slider.animation, {timeScale:0}, {duration: 1, timeScale:1, ease:"power1.in"});
      // },
    });
  },
  resize: () => {
    Slider.contentWidth = Slider.item.querySelector("img").offsetWidth * 2;
    // gsap.set(Slider.item, {x: -Slider.contentWidth/2})
    // Slider.ref.inner.style.setProperty('--ticker-width', Slider.contentWidth*2 + 'px')

    // if(Slider.animation) Slider.animation.play(0);

    Slider.animation = gsap.to(Slider.item, {
      duration: 100,
      x: -Slider.contentWidth,
      ease: "none",
      repeat: -1,
      overwrite: true,
    });

    Slider.animation.pause();
  },
  colors: (el) => {
    document.getElementById("background-background").classList.add("animate");
  },
  stopColors: (_) => {
    document
      .getElementById("background-background")
      .classList.remove("animate");
  },
  reset: (_) => {
    if (Slider.draggables.length) {
      Draggable.get(".drawer").kill();
      gsap.set(".drawer", {
        clearProps: "all",
      });
      Slider.create();
    } else {
      Slider.create();
    }
  },
};
export default Slider;
